module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Prompt Studio","short_name":"Prompt Studio","description":"Prompt Studio is a collaborative prompt editor and workflow builder, helping your team write better content with AI, faster. Connect with different LLMs, create prompt templates and make prompt engineering easy for everyone in your team.","icon":"./src/images/logo.svg","icon_options":{"purpose":"any maskable"},"background_color":"#f4f2ec","theme_color":"#0A0D15","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"464ce362bd21ebb45069fd1a85f3b244"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
